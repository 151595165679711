<template>
  <div>
    <el-row :gutter="12">
      <el-col :span="12">
        <el-card style="height:500px;">
          <div slot="header" class="clearfix">
            <span>{{$t('home.aboutus')}}</span>
          </div>
          <div style="height:435px; overflow:scroll;" v-html="$i18n.locale == 'zh-cn'? companyData.CompanyBriefChn : companyData.CompanyBriefEng">
          </div>
        </el-card>
      </el-col>

      <el-col :span="12">
        <el-card style="height:500px;">
          <div slot="header" class="clearfix">
            <span>{{$t('home.contactus')}}</span>
          </div>
          <div>
            <h5>{{$i18n.locale == 'zh-cn'? companyData.CompanyNameChn :companyData.CompanyNameEng}}</h5>
            <el-table :data="$i18n.locale == 'zh-cn'? contactData.chn:contactData.eng" :show-header="false" border fit>
              <el-table-column prop="name" min-width="80px">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                      <el-tag type="info">{{ scope.row.name }}</el-tag>
                    </div>
                </template>
              </el-table-column>
              <el-table-column prop="content" min-width="300px"></el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row style="margin-top:12px;">
      <el-col>
        <el-card>
          <div slot="header" class="clearfix">
            <span>{{$t('home.products')}}</span>
            <el-button
              style="float: right; padding: 3px 0; font-size:20px; font-weight: bolder"
              type="text"
              @click="toProducts"
            >{{$t('home.more')}}</el-button>
          </div>
          <el-row :gutter="12">
            <el-col :span="6" v-for="product in products" :key="product.ProductID">
              <el-card shadow="hover">
                <img :src="$store.state.baseurl+'/Images/'+product.ProductImgUrl.split(' ')[0]" class="image" />
                <div style="padding: 14px;">
                  <p class="content">{{$i18n.locale == 'zh-cn'? product.ProductNameChn: product.ProductNameEng}}</p>
                  <p class="content">{{$i18n.locale == 'zh-cn'? product.ProductSizeChn: product.ProductSizeEng}}</p>
                  <div style="margin-top: 12px;">
                  <el-button type="warning" @click="handleClick(product.ProductID)">{{$t('home.details')}}</el-button>
                </div>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'Home',
  data() {
    return {
      companyData:{
      },
      contactData: [],
      products:[]
    }
  },
  methods: {
    toProducts() {
      this.$router.push('/products/all')
    },
    handleClick(pid){
       this.$router.push('/product/' + pid)
    }
  },
  created(){
    this.$http.get('/company/getcompany').then(res=>{
      this.companyData=res.data.data
      let pids=[]
      var list=(res.data.data.GoodProductIDs ||'').split(' ')
      list.forEach(element => {
        pids.push(element)
      });
      this.$http.post('/product/GetProductsByIds',pids).then(resp=>{
        this.products=resp.data.data
      })
      })
    this.$http.get('/contact/getcontacts').then(res=>{
      this.contactData=res.data.data
      })
    
}
}
</script>
<style scoped>
>>> p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    line-height: 20px;
}
.clearfix {
  font-size: 20px;
  font-weight: bolder;
}
.image {
  width: 100%;
  display: block;
}
.content{
  width: 95%;

    white-space: nowrap;

    text-overflow: ellipsis;
 
    overflow: hidden;
}


</style>

